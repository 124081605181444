import { Controller } from "stimulus"
import { waitForCryptohandler } from "../src/crypto_handler_importer"

export default class extends Controller {
  // Lifecycle

  async connect() {
    this.cryptoHandler = await waitForCryptohandler(this.application)
    if (!this.cryptoHandler) { return }

    await this.decryptMessage()
  }

  async decryptMessage() {
    try {
      const decryptedMessage = await this.cryptoHandler.decryptText(this.element.innerText)
      this.element.innerText = decryptedMessage
    } catch (error) {}
  }
}
