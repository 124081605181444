import { Controller } from "stimulus"
import { detect } from "detect-browser"
import { navigateTo } from "../src/navigation"

export default class extends Controller {
  static values = { redirectUrl: String }

  connect() {
    const browser = detect()

    if (!browser || browser.name == "ie") {
      navigateTo(this.redirectUrlValue)
    }
  }
}
